import { Link } from "gatsby"
import React from "react"
import Seo from "../components/Seo"

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404" />
      <section className="error-page">
        <h3>Page was not found</h3>
        <Link to="/" className="submit-btn">
          На главную
        </Link>
      </section>
    </>
  )
}

export default NotFoundPage
